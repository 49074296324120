<template>
    <div>
        <transition name="slide-fade">
            <keep-alive include="PayerList">
                <payer-list v-if="!payerSelected" />
                <payer-view v-else />
            </keep-alive>
        </transition>
    </div>
</template>

<script>
import PayerList from "../components/payers/PayerList.vue"
import PayerView from "../components/payers/PayerView.vue"

export default {
  name: 'Payers',
  components: {
      PayerList,
      PayerView
  },
  data(){
      return {
          payers: [],
          search: {}
      }
  },
  methods: {
  },
  computed: {
      payerSelected: function(){
          return (this.$store.state.payers.selected_payer != null);
      }
  },
  deactivated() {
      this.$store.commit("payers/clearSelectedPayer");
  }
}
</script>