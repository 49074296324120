<template>
    <b-card class="avo-info-card mb-4">
        <div class="avo-header-text mb-4">Source Aliases</div>
        <b-row class="mb-4">
            <b-col>
                <div class="label-text mb-3">
                    eClinicalWorks Insurance IDs
                </div>
                <div class="data-text-large">
                    <b-badge  
                        pill
                        href="#!"
                        v-for="source_ref in ecw_source_refs" 
                        :key="source_ref.source_ref + '-' + source_ref.practice_id"
                        variant="light"
                        class="mr-2 p-2 mb-2 border border-secondary border-1 text-secondary"
                        @click="removePayerSourceRef(source_ref, 'ecw')"
                        >
                        {{source_ref.source_ref}} ({{source_ref.practice_id}})
                        <b-icon icon="x-circle-fill" class="ml-1" :v-if="canEdit" />
                    </b-badge>
                    <div v-if="ecw_source_refs.length < 1">
                        <b-overlay 
                            :show="source_refs_loading">
                            No eCW Insurance IDs
                        </b-overlay>
                    </div>
                    <br />
                    <b-badge 
                        pill
                        href="#!"
                        variant="primary"
                        class="mr-2 text-light border border-primary border-1 p-2 mt-1"
                        :v-if="canEdit"
                        @click="showAddSourceRefModal('ecw')"
                        >
                        <b-icon icon="plus-circle-fill" class="mr-2"/> 
                        Add eCW insID
                    </b-badge>
                </div>
            </b-col>
            <b-col>
                <div class="label-text mb-3">
                    Allscripts Carrier IDs
                </div>
                <div class="data-text-large">
                    <b-badge  
                        pill
                        href="#!"
                        v-for="source_ref in allscripts_source_refs" 
                        :key="source_ref.source_ref + '-' + source_ref.practice_id"
                        variant="light"
                        class="mr-2 p-2 mb-2 border border-secondary text-secondary"
                        @click="removePayerSourceRef(source_ref, 'allscripts')"
                        >
                        {{source_ref.source_ref}} ({{source_ref.practice_id}})
                        <b-icon icon="x-circle-fill" class="ml-1" :v-if="canEdit" />
                    </b-badge>
                    <div v-if="allscripts_source_refs.length < 1">
                        <b-overlay 
                            :show="source_refs_loading">
                            No Allscripts Carrier IDs
                        </b-overlay>
                    </div>
                    <br />
                    <b-badge 
                        pill
                        variant="primary"
                        href="#!"
                        class="mr-2 text-light border border-primary p-2 mt-1"
                        :v-if="canEdit"
                        @click="showAddSourceRefModal('allscripts')"
                        >
                        <b-icon icon="plus-circle-fill" class="mr-2"/> 
                        Add Allscripts CarrierID
                    </b-badge>
                </div>
            </b-col>
            <b-modal id="add-source-ref-modal" hide-footer hide-header>
                <div class="avo-header-text" style="text-align:center">
                    Add new source reference
                </div>
                <b-alert
                    v-model="showAlertModal"
                    variant="danger"
                    dismissible>
                    <b>{{errorTextModal}}</b>
                </b-alert> 
                <!-- Practice select -->
                <b-form-group 
                    label="Practice" 
                    label-for="practice_id"
                    :state="!new_source_ref_do_validate ? null : new_source_ref.practice != null"
                    invalid-feedback="Please select a practice"
                    description="Select a primary practice for import association, may also be used for other practices on the same external PM system">
                    <practice-multiselect 
                        id="practice_id"
                        :closeOnSelect="true"
                        :multiple="false"
                        :state="!new_source_ref_do_validate ? null : new_source_ref.practice != null"
                        v-model="new_source_ref.practice" />
                </b-form-group>
                <!-- Source ref input -->
                <b-form-group 
                    label="Source Reference ID" 
                    label-for="source_ref"
                    :state="!new_source_ref_do_validate ? null : new_source_ref.source_ref != null"
                    invalid-feedback="Please enter a data source reference/alias ID"
                    description="Carrier ID or Insurance ID of payer in external PM system">
                    <b-form-input 
                        id="source_ref"
                        v-model="new_source_ref.source_ref"
                        placeholder="e.g. 12345"
                        type="number"
                        required
                        :state="!new_source_ref_do_validate ? null : new_source_ref.source_ref != null"
                        />
                </b-form-group>
                <div class="d-flex justify-content-end mt-4">
                    <b-button class="avo-basic-btn" @click="hideAddSourceRefModal">
                        Cancel
                    </b-button>
                    <b-button class="avo-primary-btn ml-2" @click="addPayerSourceRef()">
                        Add
                    </b-button>
                </div>
            </b-modal>
        </b-row>
    </b-card>
</template>


<script>
import { make_url, permissionsMixin, authenticated_request } from '../../common.js'
import PracticeMultiselect from '../multiselects/PracticeMultiselect.vue'

export default {
    name: 'PayerSourceRefs',
    mixins: [permissionsMixin],
    components: {
        PracticeMultiselect,
    },
    props: {
        payer: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            source_refs_loading: true,
            ecw_source_refs: [],
            allscripts_source_refs: [],
            new_source_ref: {
                practice: null,
                source_ref: null,
                data_source: 'ecw',
            },
            new_source_ref_do_validate: false,
            showAlertModal: false,
            errorTextModal: '',
        }
    },
    methods: {
        getPayerSourceRefs() {
            const url = make_url("/payers/" + this.payer.payer_id + "/source_refs");
            const vm = this;
            const promise = authenticated_request({
                method: "get",
                url: url,
            }).then(function(response){
                if (response) {
                    vm.ecw_source_refs = response.data.ecw_source_refs;
                    vm.allscripts_source_refs = response.data.allscripts_source_refs;
                }
            }).catch(function(error){
                console.log(error.response)
            }).finally(()=>{
                vm.source_refs_loading = false;
            });
            return promise;
        },
        removePayerSourceRef(source_ref, data_source) {
            if (confirm(`Remove ${data_source} source alias ${source_ref.source_ref} (practice_id=${source_ref.practice_id}) from payer?`) !== true) {
                return
            }
            const url = make_url("/payers/" + this.payer.payer_id + "/source_refs");
            const vm = this;
            const params = {
                'practice_id' : source_ref.practice_id,
                'source_ref': source_ref.source_ref,
                'data_source': data_source,
            };
            const promise = authenticated_request({
                method: 'delete',
                url: url,
                params: params
            }).then(function(){
                vm.ecw_source_refs = vm.ecw_source_refs.filter(item => item !== source_ref);
                vm.allscripts_source_refs = vm.allscripts_source_refs.filter(item => item !== source_ref);
            }).catch(function(error){
                console.log(error.response)
            });
            return promise
        },
        showAddSourceRefModal(data_source) {
            if (!this.canEdit) {
                return
            }
            this.new_source_ref.data_source = data_source;
            this.$bvModal.show('add-source-ref-modal');
        },
        clearSourceRefModal() {
            // clear data
            this.new_source_ref.practice = null;
            this.new_source_ref.source_ref = null;
            // clear validators
            this.new_source_ref_do_validate = false;
            // clear errors
            this.errorTextModal = '';
            this.showAlertModal = false;
        },
        hideAddSourceRefModal() {
            this.$bvModal.hide('add-source-ref-modal');
            this.clearSourceRefModal();
        }, 
        addPayerSourceRef() {
            const data_source = this.new_source_ref.data_source;
            const url = make_url("/payers/" + this.payer.payer_id + "/source_refs");
            const data = {
                'practice_id' : this.new_source_ref.practice?.practice_id,
                'source_ref': this.new_source_ref.source_ref,
                'data_source': this.new_source_ref.data_source
            };
            this.new_source_ref_do_validate = true;
            let invalid = false; 
            if (data['practice_id'] == null) {
                invalid = true;
            } 
            if (data['source_ref'] == null) {
                invalid = true;
            }
            if (invalid) {
                return
            }
            const vm = this;
            const promise = authenticated_request({
                method: 'post',
                url: url,
                data: data
            }).then(function(){
                if (data_source === 'ecw') {
                    vm.ecw_source_refs.push(data);
                }
                else if (data_source == 'allscripts') {
                    vm.allscripts_source_refs.push(data);
                }
                // hide modal and clear data
                vm.hideAddSourceRefModal();
            }).catch(function(error){
                // show errors
                vm.errorTextModal = error.response.data.msg;
                vm.showAlertModal = true;
            });
            return promise
        },
        formattedFullDate(datetime) {
            return datetime ? formatFullDate(datetime) : 'None';
        },
    },
    
    computed: {
        payer_id: function() {
            return this.payer?.payer_id;
        },

        canEdit: function(){
            return this.hasPermission('edit');
        }
    },
        
    async mounted () {
        this.getPayerSourceRefs();
    }
}
</script>