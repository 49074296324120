<template>
<div>
    <b-alert
        v-model="showAlert"
        variant="danger"
        style="margin-left:20px"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <div class="d-flex justify-content-between">
        <div class="d-flex mb-4" style="">
            <div class="avo-page-header-text" style="margin-right:30px">
                Payers
            </div>
            <div>
            <b-input-group class="search-group">
                <b-input-group-prepend>
                    <span class="input-group-text"><b-icon icon="search"></b-icon></span>
                </b-input-group-prepend>
                <b-form-input
                    @keyup="debouncePayers"
                    @search="debouncePayers"
                    class="payer-search-input pl-1"
                    type="search" v-model="search_params.search"
                    placeholder="Try typing a payer's name"/>                
                <b-input-group-append >
                    <span class="divider"></span>
                    <b-dropdown class="dropdown type-dropdown">
                        <template #button-content>
                            {{typeInputLabel}}
                        </template>
                        <b-dropdown-form>
                            <div style="font-size:20px;font-weight:300;margin-bottom:15px;">Types</div>
                            <b-form-checkbox-group
                                v-model="search_params.payer_types"
                                :options="payer_types"
                                @change="debouncePayers">
                            </b-form-checkbox-group>
                        </b-dropdown-form>
                    </b-dropdown>
                    <span class="divider"></span>
                    <b-dropdown class="dropdown filter-dropdown" :boundary="'window'">
                        <template #button-content>
                        <b-icon-funnel class="mr-2" />{{filterInputLabel}}
                        </template>
                        <b-dropdown-form class="d-flex flex-column align-items-start">
                        
                        <div class="selecter-label">Assigned to</div>
                        <multiselect
                            v-model="search_params.workers"
                            :hide-selected="true"
                            :options="workers"
                            track-by="worker_id" 
                            label="name"
                            :multiple="true"
                            :show-labels="false"
                            placeholder="-- Select an Option --">
                        </multiselect>
                        
                        <div class="d-flex justify-content-end mt-4">
                            <b-button @click="clearFilters" variant="outline-primary" class="avo-basic-btn">Clear Filters</b-button>
                            <b-button @click="searchPayers" variant="outline-primary" class="avo-primary-btn ml-3">Apply</b-button>
                        </div>
                        </b-dropdown-form>
                    </b-dropdown>
                </b-input-group-append>
            </b-input-group>
            </div>
        </div>
        <div v-if="canViewButtons()" class="d-flex">
            <div class="mr-3">
            <b-button v-b-modal.manage-payer-groups variant="outline-primary" class="avo-primary-btn mt-2">Manage Payer Groups</b-button>
            <payer-manage-groups-modal @submitted="searchPayers()" />
            </div>
            <div>
            <b-button v-b-modal.new-payer variant="outline-primary" class="avo-primary-btn mt-2">Add payer</b-button>
            <payer-add @submitted="searchPayers()" />
            </div>
        </div>
    </div>
    <div>
        <div 
        :class="'avo-table-lrg-wrapper'">
        <b-table id="payers-table" 
            :per-page="perPage" 
            :current-page="currentPage" 
            :items="getPayers"
            :fields="payersFields"
            :selectable="hasPermission('select_payer')"
            :busy="tableLoading"
            select-mode="single"
            @row-selected="setSelectedPayer"
            class="avo-table-lrg"
            primary-key="payer_id"
            ref="payerTable"
            show-empty
            :emptyHtml="emptyPayerListMessage()"
            striped>

            <template #table-busy>
            <div class="text-center text-secondary my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Fetching payers...</strong>
            </div>
            </template>

            <template #cell(group)="data">
            <div><strong>{{ data.value || '--' }}</strong></div>
            </template>

            <template #cell(name)="data">
            <div><strong>{{ data.value || '--' }}</strong></div>
            </template>
            
            <template #cell(state)="data">
            <div>{{ data.value || '--' }} </div>
            </template>

            <template #cell(zip)="data">
            <div>{{ data.value || '--' }} </div>
            </template>

            <template #cell(full_address)="data">
            <div>{{ data.value || '--' }} </div>
            </template>

            <template #cell(telephone)="data">
            <div>{{ data.value || '--' }} </div>
            </template>            
        </b-table>
        </div>

        <b-pagination
        v-if="payers.length > 0"
        align="center"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        aria-controls="payers-table"
        ></b-pagination>
    </div>
</div>
</template>

<script>
import { make_url, authenticated_request, debounce, permissionsMixin, alertsMixin } from '../../common.js'
import Multiselect from 'vue-multiselect'
import PayerAdd from './PayerAdd.vue'
import PayerManageGroupsModal from './PayerManageGroupsModal.vue'

export default {
  name: 'PayerList',
  mixins: [permissionsMixin, alertsMixin ],
  components: {
      Multiselect,
      PayerAdd,
      PayerManageGroupsModal
  },
  data(){
    return {
        payers: [],
        search_params: {
            search: "",
            payer_types: [],
            workers: [],
        },
        payer_types: [],
        workers: [],
        
        perPage: 20,
        totalRows: 1,
        currentPage: 1,
        tableLoading: null,
    }
  },
  methods: {
    getPayers: function(){
        var params = this.formattedParams;

        const url = make_url("/reports/payers");
        const self = this;
        const promise = authenticated_request({
            method: "get",
            url: url,
            params: params
        }).then(function(response){
            if (response) {
                const payers = response.data.payers;
                self.payers = payers;
                self.totalRows = response.data.pagination.total;
                self.perPage = response.data.pagination.per_page;
                self.tableLoading = false;
                return payers || [];
            }
        }).catch(function(error){
            self.showError(error);
            console.log(error.response)
        });
        
        return promise;
    },
    setSelectedPayer(items){
        if (this.hasPermission('select_payer')) {
            this.$store.commit("payers/selectPayer", items[0]);
        } else {
            return
        }
    },
    searchPayers: function() {
        // If table has already finished loading, this is an update
        this.tableLoading = (this.tableLoading == false) ? false : true;
        
        if (this.search_params.search.length > 2 || this.search_params.search.length == 0) {
            this.currentPage = 1;
            this.getPayers();
            this.$refs.payerTable.refresh();
        }
    },
    getFilters: function(){
        const url = make_url("/reports/payers/filters");
        const self = this;
        const promise = authenticated_request({
            method: "get",
            url: url,
        }).then(function(response){
            if (response) {
                self.hideError();
                const data = response.data;
                self.workers = data.workers;
                self.payer_types = data.payer_types;
                self.$store.commit("payers/setPayerTypes", data.payer_types);
                return data || [];
            }
        }).catch(function(error){
            self.showError(error);
        });
        return promise;
    },
    clearFilters: function(){
        this.search_params.workers = [];
        this.search_params.payer_types = [];
        this.currentPage = 1;
        this.getPayers();
        this.$refs.payerTable.refresh();
    },
    emptyPayerListMessage: function() {
      if (this.tableLoading) {
        return "Fetching payers for you ..."
      } else {
        return "No payers match the selected filters"
      }
    },
    //error methods
    showError(alertMessage){
        this.showSuccess = false;
        this.setShowAlert(true, alertMessage);
    },
    hideError(){
        this.setShowAlert(false);
    },
    //permissions
    canViewButtons() {
        if (this.hasPermission('edit')) {
            return true;
        } else return false
    }
  },
  computed: {
      payersFields: function(){
          return [
            {
                label: 'Payer Group',
                key: 'group_name',
            },
            {
                label: 'Payer',
                key: 'name',
            },
            {
                key: 'state',
                tdClass: 'max-width-col'
            },
            {
                key: 'zip',
                tdClass: 'max-width-col'
            },
            {
                label: 'Address',
                key: 'full_address',
            },
            {
                label: 'Phone',
                key: 'telephone',
            },
          ];
      },
      formattedParams: function() {
          var params = {
              search: this.search_params.search,
              workers: this.search_params.workers.map(obj => obj.worker_id).join(),
              payer_types: this.search_params.payer_types.join(),
              page: this.currentPage,
              per_page: this.perPage
          }
          return params
      },
      filterInputLabel: function(){
          const count = this.search_params.workers.length;
          if(count == 0) {
               return "More Filters";
          } else {
               return count + " Filter(s)";
          }
      },
      typeInputLabel: function(){
          if(this.search_params.payer_types.length == 0) {
               return "All Payers";
          } else {
              return this.search_params.payer_types.length + " Type(s)"
          }
      },
  },
  watch: {
  },
  created(){
      this.getPayers();
      this.debouncePayers = debounce(this.searchPayers, 900);
  },
  mounted(){
      this.getFilters();
  },
  activated(){
      this.$refs.payerTable.clearSelected();
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Styling for table loading message */
/* Busy table styling */
table.b-table[aria-busy='true'] {
  opacity: 0.6;
}

/* Styling the search bar group */
::v-deep .search-group {
    position: relative;
    margin-right: 20px;
    border: 1px solid #C9D2DF;
    border-radius: 5px;
    max-width:650px;
}
::v-deep .payer-search-input, .input-group-text {
    height:50px;
    border: none;
    border-radius: 5px;
    background: white;
    color: #9CA3AD; 
}
::v-deep .payer-search-input::placeholder {
    color: #9CA3AD;
    font-size:12px;
    opacity: 1;
}
::v-deep .b-dropdown .btn-secondary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-radius: 5px;
    color: #9CA3AD;
    background: white;
    font-size:12px;
    height:50px;
    min-width:150px;
    text-align:left;
}
::v-deep .b-dropdown .btn-secondary:active,
::v-deep .btn-secondary:not(:disabled):not(.disabled):active, 
::v-deep .btn-secondary:not(:disabled):not(.disabled).active, 
::v-deep .show > .btn-secondary.dropdown-toggle {
    color:#519EFF !important;
    background-color: white;
}
::v-deep .b-dropdown .btn-secondary:focus {
    box-shadow:none !important;
}
.divider {
    display: inline-block;
    width: 0;
    height: 20px;
    margin: auto;
    border-right: 1.8px solid #D2D8E2;
}

/* dropdown menu-related styling */
::v-deep .filter-dropdown .b-dropdown-form {
    padding: 20px 30px 30px 30px;
}
::v-deep .filter-dropdown .dropdown-menu {
    width:100.3%;
    right: -1px !important;
    left: auto !important;
    top:45px !important;
    transform: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0px 16px 48px #1617182B !important;
}
::v-deep .type-dropdown .dropdown-menu {
    width: 200px;
    padding: 10px;
    left: -1px !important;
    top:48px !important;
    transform: none !important;
    font-size: 14px;
    font-weight: 500;
    color: #64728C;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0px 16px 48px #1617182B !important;
}
::v-deep .type-dropdown .b-dropdown-form {
    padding: 0.2rem 0.8rem;
}
</style>