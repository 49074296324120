<template>
  <div>
    <div class="d-flex flex-row justify-content-between px-3 pb-3">
      <div class="summary-card-header-txt">Under Payments</div>
      <div class="d-flex align-items-center justify-content-end w-50">
        <div class="mr-2">By age</div>
        <multiselect
          v-model="underpaidAgeRange"
          :hide-selected="true"
          :options="ageRangeOptions"
          track-by="value"
          class="w-50"
          label="text"
          :show-labels="false"
          placeholder="Choose a range"
        />
      </div>
    </div>
    <b-table
      id="underpaid-claims-table"
      :items="getUnderpaidClaims"
      no-provider-paging
      :fields="underPaymentsFields"
      :per-page="underPaymentsPerPage"
      :current-page="underPaymentsPage"
      class="avo-table-embedded"
      primary-key="claim_id"
      ref="underpaidClaimsTable"
      show-empty
      empty-text="No underpayments"
      striped
    >
      <template #cell(claim_id)="data">
        <div>
          <strong>{{ data.value }}</strong>
        </div>
      </template>

      <template #cell(balance)="data">
        <div>{{ formatDollars(data.value) }}</div>
      </template>

      <template #cell(provider_name)="data">
        <div>{{ data.value }}</div>
      </template>

      <template #cell(last_payer_payment_date)="data">
        <div>{{ formatFullDate(data.value) }}</div>
      </template>

      <template slot="bottom-row">
        <td
          v-if="showUnderPaymentsLimitWarning"
          colspan="99"
          style="text-align: center"
        >
          Number of results limited to {{ underPaidMaxResults }}
        </td>
      </template>
    </b-table>
    <div>
      <b-pagination
        v-model="underPaymentsPage"
        :total-rows="underPaymentsTotalCount"
        :per-page="underPaymentsPerPage"
        align="center"
      />
    </div>
  </div>
</template>

<script>
import {
  make_url,
  authenticated_request,
  formatFullDate as commonFormatFullDate,
  formatDollarsNoCents as formatDollars,
} from "../../common.js";
import Multiselect from "vue-multiselect";

export default {
  name: "PayerUnderpayments",
  components: {
    Multiselect,
  },
  props: {
    payer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ageRangeOptions: [
        { text: "All ages", value: "" },
        { text: "0-30 days", value: "0,30" },
        { text: "31-60 days", value: "31,60" },
        { text: "61-90 days", value: "61,90" },
        { text: "91-120 days", value: "91,120" },
        { text: "120+ days", value: "121,9999" },
      ],
      underpaidAgeRange: { text: "All ages", value: "" },

      // We will do our own pagination client-side
      underPaymentsPage: 1,
      underPaymentsPerPage: 10,
      underPaymentsTotalCount: 0,

      // Pagination on the server-side is limited to 500 results
      underPaidMaxResults: 500,
    };
  },
  methods: {
    formatDollars,
    formatFullDate(datetime) {
      return datetime ? commonFormatFullDate(datetime) : "N/A";
    },
    getUnderpaidClaims() {
      const url = make_url("/reports/claims/underpaid");
      const vm = this;
      var params = {
        payer_ids: this.payer.payer_id,
        per_page: this.underPaidMaxResults, // we'll handle pagination on client
        age_ranges: this.underpaidAgeRange.value,
        min_balance: 0,
      };
      const promise = authenticated_request({
        method: "get",
        url: url,
        params: params,
      })
        .then(function (response) {
          if (response) {
            const claims = response.data.claims;

            const pagination = response.data.pagination;
            vm.underPaymentsTotalCount = pagination.total;

            return claims || [];
          }
        })
        .catch(function (error) {
          console.log(error.response);
        });
      return promise;
    },
  },
  computed: {
    underPaymentsFields: function () {
      return [
        {
          label: "Claim",
          key: "claim_id",
          tdClass: "max-width-col",
        },
        {
          label: "Net Amount",
          key: "balance",
          thClass: "right-align-col",
          tdClass: "net-amount-col",
        },
        {
          label: "Provider",
          key: "provider_name",
        },
        {
          label: "Last Payment",
          key: "last_payer_payment_date",
          thClass: "right-align-col",
          tdClass: "right-align-col",
        },
      ];
    },
    showUnderPaymentsLimitWarning: function () {
      const onLastPage =
        this.underPaymentsPage * this.underPaymentsPerPage >=
        this.underPaymentsTotalCount;
      const resultsFull =
        this.underPaymentsTotalCount >= this.underPaidMaxResults;
      return onLastPage && resultsFull;
    },
  },
  watch: {
    underpaidAgeRange: function () {
      this.$refs.underpaidClaimsTable.refresh();
    },
  },
};
</script>

<style scoped>
::v-deep .net-amount-col {
  text-align: right;
  color: #e7a33a;
}
</style>