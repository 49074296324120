<template>

<b-container class="p-0">
<b-row style="margin:auto">
    <b-col style="padding:0px">
        <payer-claim-list />
    </b-col>
</b-row>
</b-container>
</template>

<script>
//import { make_url, authenticated_request, debounce } from '../../common.js'
//import Multiselect from 'vue-multiselect'
import PayerClaimList from './PayerClaimList.vue'

export default {
    name: 'PayerClaims',
    components: {
        PayerClaimList
    },
    data(){
        return {

        }
    },
    methods: {

    },
    computed: {
        
    },
    watch: {
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* increasing b-containers max-width for larger screens */
@media only screen and (min-width : 1200px) {
   .container { max-width: 1400px; } 
} 
</style>