<template>
<div id="add-payer">
<b-modal id="new-payer" @hide="resetModal" title="New Payer" hide-footer size="md">
<b-form>
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <div>
        <div>
            <b-form-group
            id="name"
            label="Company Name">
            <b-input-group>
                <b-form-input v-model="payer.name"></b-form-input>
            </b-input-group>
            </b-form-group>
        </div>
        <div>
            <b-form-group
                id="payer_type"
                label="Payer Type">
                <b-form-select v-model="payer.payer_type" :options="payerTypes">
                    <template #first>
                    <b-form-select-option :value="null" disabled>Select type</b-form-select-option>
                    </template>
                </b-form-select>
            </b-form-group>
        </div>
        <div>
            <b-form-group
            id="timely_filing"
            label="Timely Filing">
            <b-input-group>
                <b-form-input type="number" v-model="payer.timely_filing"></b-form-input>
            </b-input-group>
            </b-form-group>
        </div>
        <b-card class="mt-4 mb-4">
            <div class="avo-header-text">
                Address and Contact Info
            </div>
            <hr style="margin-right:-20px;margin-left:-20px">
            <div>
                <b-form-group
                id="address_line1"
                label="Address">
                <b-input-group>
                    <b-form-input v-model="payer_address.address_line1"></b-form-input>
                </b-input-group>
                </b-form-group>
            </div>
            <div class="d-flex">
                <div class="mr-2">
                    <b-form-group
                    id="city"
                    label="City">
                    <b-input-group>
                        <b-form-input v-model="payer_address.city"></b-form-input>
                    </b-input-group>
                    </b-form-group>
                </div>
                <div class="mr-2 ml-2">
                    <b-form-group
                    id="state"
                    label="State">
                    <b-input-group>
                        <b-form-input v-model="payer_address.state"></b-form-input>
                    </b-input-group>
                    </b-form-group>
                </div>
                <div class="ml-2">
                    <b-form-group
                    id="zip"
                    label="Zip">
                    <b-input-group>
                        <b-form-input v-model="payer_address.zip"></b-form-input>
                    </b-input-group>
                    </b-form-group>
                </div>
            </div>
            <div class="d-flex">
                <div class="mr-2">
                    <b-form-group
                    id="fax"
                    label="Fax">
                    <b-input-group>
                        <b-form-input v-model="payer_address.fax"></b-form-input>
                    </b-input-group>
                    </b-form-group>
                </div>
                <div class="ml-2">
                    <b-form-group
                    id="telephone"
                    label="Claim Phone">
                    <b-input-group>
                        <b-form-input v-model="payer_address.telephone"></b-form-input>
                    </b-input-group>
                    </b-form-group>
                </div>
            </div>
            <div>
                <b-form-group
                id="email"
                label="Billing Email">
                <b-input-group>
                    <b-form-input v-model="payer_address.email"></b-form-input>
                </b-input-group>
                </b-form-group>
            </div>
            <div>
                <b-form-group
                id="website"
                label="Website">
                <b-input-group>
                    <b-form-input v-model="payer_address.website"></b-form-input>
                </b-input-group>
                </b-form-group>
            </div>
        </b-card>
        <div class="mt-4" style="text-align:right">
            <b-button @click="validatePayerInputs" class="avo-primary-btn">
                Create Payer
            </b-button>
        </div>
    </div>
</b-form>
</b-modal>
</div>
</template>

<script>
import { make_url, authenticated_request, alertsMixin } from '../../common.js'

export default {
  name: 'PayerAdd',
  mixins: [alertsMixin],
  data: function(){
      return {
            payer: {
                name: null,
                payer_type: -1,
                timely_filing: null
            },
            payer_address: {
                telephone: null,
                fax: null,
                address_line1: null,
                city: null,
                state: null,
                zip: null,
                email: null,
                website: null
            },
      }
  },
  methods: {
    clearData(){
            this.payer = {
                name: null,
                payer_type: -1,
                timely_filing: null
            };
            this.payer_address = {
                telephone: null,
                fax: null,
                address_line1: null,
                city: null,
                state: null,
                zip: null,
                email: null,
                website: null
            };
        this.hideError();
    },
    validatePayerInputs(){
        this.hideError();
        
        if ((this.payer.name == "") || (this.payer.name == null)){
            this.showError("Please enter a payer name");
            return false;
        }
        
        var newPayer = {
            payer: {},
            address: {}
        }
        
        for (var key in this.payer){
            if ((this.payer[key] != "") && (this.payer[key] != null)){
                newPayer.payer[key] = this.payer[key]
            }
        }
        
        for (key in this.payer_address){
            if ((this.payer_address[key] != "") && (this.payer_address[key] != null)){
                newPayer.address[key] = this.payer_address[key]
            }
        }
        
        this.createPayer(newPayer);
    },
    createPayer(newPayer){
        const url = make_url("/forms/payer_with_address");
        const self = this;
        const promise = authenticated_request({
            method: "post",
            url: url,
            data: newPayer
        }).then(function(response){
            if (response){
                self.$bvModal.hide('new-payer');
                self.$emit('submitted');
            }
        }).catch(function(error){
            self.showError(error);
        });
        return promise;
    },
    resetModal(){
        this.hideError();
        this.clearData();
    },
    showError(alertMessage){
        this.setShowAlert(true, alertMessage);
    },
    hideError(){
        this.setShowAlert(false);
    },
  },
  computed:{
      payerTypes: function(){
          return this.$store.state.payers.payer_types;
      }
  },
}
</script>

<style scoped>

</style>