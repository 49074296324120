<template>
<div id="modify-payer-resource">
<b-modal id="edit-payer-resource" @hide="resetModal" title="Edit Payer Login" hide-footer size="lg">
<b-form @submit="onSubmit">
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <b-row v-if="master_password_needed">
        <b-col>
            <b-form-group
            id="master_password"
            label="Master password:">
            <b-input-group>
                <b-form-input style="text-security:disc; -webkit-text-security:disc;" v-model="form.master_password"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-group
            id="name"
            label="Name:">
            <b-input-group>
                <b-form-input v-model="form.name"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="url"
            label="URL:">
            <b-input-group>
                <b-form-input v-model="form.url"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
        <b-form-group
            id="flag"
            label="Flag:">
            <b-form-select v-model="form.flag" :options="flags">
                <template #first>
                <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                </template>
            </b-form-select>
        </b-form-group>
        </b-col>
        <b-col>
        <b-form-group
            id="type"
            label="Type:">
            <b-form-select v-model="form.type" :options="types">
                <template #first>
                <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                </template>
            </b-form-select>
        </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-group
            id="username"
            label="Username:">
            <b-input-group>
                <b-form-input v-model="form.username"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="password"
            label="New Password:">
            <b-input-group>
                <b-form-input type="password" autocomplete="new-password" v-model="form.password"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="confirmpassword"
            label="Confirm new password:">
            <b-input-group>
                <b-form-input type="password" v-model="form.confirm_password"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-group
            id="email"
            label="Email:">
            <b-input-group>
                <b-form-input v-model="form.email"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="admin"
            label="Admin:">
            <b-input-group>
                <b-form-input v-model="form.admin"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="telephone"
            label="2FA Phone #:">
            <b-input-group>
                <b-form-input v-model="form.telephone"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-group
                id="security_question_answers"
                label="Security Question Answers:">
                <b-form-textarea v-model="form.security_question_answers"
                placeholder="Enter security questions with answers"></b-form-textarea>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row v-if="hasPermission('assign_resources')">
        <b-col>
            <b-form-group
                id="selected_practice"
                label="Assign to a Practice">
                <practice-multiselect
                    v-model="selected_practice"
                    :multiple="false" 
                    />
            </b-form-group>
        </b-col>
    </b-row>
    <b-row v-if="hasPermission('assign_resources')">
        <b-col>
            <b-form-group
                id="selected_workers"
                label="Assign To:">
                <worker-multiselect
                    v-model="selected_workers"
                    :multiple="true"
                    @select="new_workers = true"
                    @remove="new_workers = true" />
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col style="text-align:center">
            <b-button @click="onSubmit" class="avo-primary-btn">
                Submit Login Changes
            </b-button>
        </b-col>
    </b-row>
</b-form>
</b-modal>
</div>
</template>

<script>
import { make_url, authenticated_request, permissionsMixin, alertsMixin } from '../../common.js'
import WorkerMultiselect from '../multiselects/PayerMultiselect.vue'
import PracticeMultiselect from '../multiselects/PracticeMultiselect.vue'

export default {
  name: 'PayerResourceEdit',
  mixins: [ permissionsMixin, alertsMixin ],
  components:{
      WorkerMultiselect,
      PracticeMultiselect,
  },
  data: function(){
      return {
            form: {
                master_password: null,
                name: null,
                flag: null,
                type: null,
                url: null,
                email: null,
                username: null,
                password: null,
                confirm_password: null,
                security_question_answers: null,
                admin: null,
                telephone: null,
            },
            new_resource: {},
            passwords: ["password", "confirm_password", "master_password"],
            required_form_inputs: ["name",],
            optional_form_inputs: ["flag", "url", "username", "type", "email", "security_question_answers", "admin", "telephone", "selected_practice"],
            new_password: false,
            selected_workers: [],
            new_workers: false,
            selected_practice: null,
      }
  },
  methods: {
    clearData(){
        this.form = {
            master_password: null,
            name: null,
            flag: null,
            type: null,
            url: null,
            email: null,
            username: null,
            password: null,
            confirm_password: null,
            security_question_answers: null,
            admin: null,
            telephone: null,
        };
        this.new_resource = {}
        this.selected_workers = [];
        this.selected_practice = null;
        this.hideError();
    },
    validateInputs(){
        //only include inputs that have been changed
        this.hideError();
        this.new_resource = {};
        this.new_password = false;
        
        for (var i = 0; i < this.required_form_inputs.length; i++){
            if (this.form[this.required_form_inputs[i]] != this.resource[this.required_form_inputs[i]]){
                if ((this.form[this.required_form_inputs[i]] == null) || (this.form[this.required_form_inputs[i]] == "")){
                    this.showError("Missing field: " + this.required_form_inputs[i]);
                    return false;
                }
                this.new_resource[this.required_form_inputs[i]] = this.form[this.required_form_inputs[i]];
            }
        }
        for (i = 0; i < this.optional_form_inputs.length; i++){
            if (this.form[this.optional_form_inputs[i]] != this.resource[this.optional_form_inputs[i]]){
                this.new_resource[this.optional_form_inputs[i]] = this.form[this.optional_form_inputs[i]];
            }
        }
        
        if (((this.form.password != null) && (this.form.password != "")) ||
            ((this.form.confirm_password != null) && (this.form.confirm_password != ""))){
            if (this.form.password != this.form.confirm_password){
                this.showError("Passwords do not match");
                return false;
            }
            // if ((this.form.master_password == null) || (this.form.master_password == "")){
            //     this.showError("Missing field: master password")
            //     return false;
            // }
            this.new_password = true;
        }
        
        return true
    },
    editResource(){
        const url = make_url("/resources/" + this.resource_id);
        const self = this;
        const promise = authenticated_request({
            method: "put",
            url: url,
            data: this.new_resource
        }).then(function(response){
            if (response){
                if (self.new_workers){
                    self.assignWorkersToLogin(self.resource_id);
                }
                else{
                    self.$bvModal.hide('edit-payer-resource');
                    self.$emit('submitted');
                }
            }
        }).catch(function(error){
            self.showError(error);
        });
        return promise;
    },
    changeResourcePassword(){
        const url = make_url("/resources/" + this.resource_id + "/passwords");
        const self = this;
        const promise = authenticated_request({
            method: "put",
            url: url,
            data: {
                'password': this.form.password,
                'master_password': this.form.master_password
            }
        }).then(function(response){
            if (response){
                self.editResource(self.resource_id);
            }
        }).catch(function(error){
            if (error.response.status == 400){
                self.showError("Invalid password");
            }
            else{
                self.showError(error);
            }
        });
        return promise;
    },
    updatePracticeAssociation(newPractice){
        const url = make_url("/resources/" + this.resource_id + "/practice/payer");
        const vm = this;
        const promise = authenticated_request({
            method: "put",
            url: url,
            data: {
                'practice_id': newPractice,
                'payer_id': vm.payer_id
            }
        }).then(function(response){
            if (response){
                console.log("practice association updated")
            }
        }).catch(function(error){
            console.log(error.response)
            self.showError(error);
        });
        return promise;
    },
    assignWorkersToLogin(resource_id){
        var workers_list = [];
        for (var i = 0; i < this.selected_workers.length; i++){
            workers_list.push(this.selected_workers[i].worker_id);
        }
        const url = make_url("/workers/resources/" + resource_id);
        const self = this;
        const promise = authenticated_request({
            method: "post",
            url: url,
            data: {
                'workers': workers_list
            }
        }).then(function(response){
            if (response){
                self.$bvModal.hide('edit-payer-resource');
                self.$emit('submitted');
            }
        }).catch(function(error){
            self.showError(error);
        });
        return promise;
    },
    getSelectedWorkers(){
        const url = make_url("/resources/" + this.resource_id + "/workers");
        const self = this;
        const promise = authenticated_request({
            method: "get",
            url: url,
        }).then(function(response){
            if (response){
                self.selected_workers = response.data.workers;
            }
        }).catch(function(error){
            self.showError(error);
        });
        return promise;
    },
    onSubmit(){
        if (this.validateInputs()){
            var newPractice = this.selected_practice ? this.selected_practice.practice_id : null;
            var oldPractice = this.resource.practice ? this.resource.practice.practice_id : null;
            if (oldPractice != newPractice){
                this.updatePracticeAssociation(newPractice);
            }
            if (this.new_password){
                this.changeResourcePassword();
            }
            else{
                this.editResource();
            }
        }
    },
    resetModal(){
        this.$store.commit("payers/clearResource");
    },
    showError(alertMessage){
        this.setShowAlert(true, alertMessage);
    },
    hideError(){
        this.setShowAlert(false);
    },
  },
    computed: {
        payer_id: function(){
            return this.$store.state.payers.selected_payer.payer_id;
        },
        resource: function(){
            return this.$store.state.payers.selected_resource;
        },
        resource_id: function(){
            return this.resource.resource_id;
        },
        worker_id: function(){
            return localStorage.getItem('worker_id');
        },
        master_password_needed: function(){
            if (this.$store.state.practices.selected_resource?.assigned_workers.split(',').includes(this.worker_id) ||
                this.$store.state.practices.selected_resource?.is_public) {
                    return true
                } else {
                    return false
                }
        },
        flags: function(){
            return this.$store.state.payers.resource_flags;
        },
        types: function(){
            return this.$store.state.payers.resource_types;
        },
    },
    watch: {
        resource: function(){
            if (this.resource){
                this.form = {
                    master_password: null,
                    name: this.resource.name,
                    flag: this.resource.flag,
                    type: this.resource.type,
                    url: this.resource.url,
                    email: this.resource.email,
                    username: this.resource.username,
                    password: null,
                    confirm_password: null,
                    security_question_answers: this.resource.security_question_answers,
                    admin: this.resource.admin,
                    telephone: this.resource.telephone,
                }
                if (this.hasPermission('assign_resources')) {
                    this.getSelectedWorkers();
                }
                this.selected_practice = this.resource.practice ? this.resource.practice : null;
            }
            else{
                this.hideError();
                this.clearData();
            }
        },
    },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
</style>