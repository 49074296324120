<template>
<div id="add-payer-resource">
<b-modal id="new-payer-resource" @hide="resetModal" title="New Payer Login" hide-footer size="lg">
<b-form @submit="onSubmit">
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <b-row>
        <b-col>
            <b-form-group
            id="name"
            label="Name">
            <b-input-group>
                <b-form-input v-model="form.name"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="url"
            label="URL">
            <b-input-group>
                <b-form-input v-model="form.url"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
        <b-form-group
            id="flag"
            label="Flag">
            <b-form-select v-model="form.flag" :options="flags">
                <template #first>
                <b-form-select-option :value="null" disabled>Select flags</b-form-select-option>
                </template>
            </b-form-select>
        </b-form-group>
        </b-col>
        <b-col>
        <b-form-group
            id="type"
            label="Type">
            <b-form-select v-model="form.type" :options="types">
                <template #first>
                <b-form-select-option :value="null" disabled>Select types </b-form-select-option>
                </template>
            </b-form-select>
        </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-group
            id="username"
            label="Username">
            <b-input-group>
                <b-form-input v-model="form.username"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="password"
            label="Password">
            <b-input-group>
                <b-form-input type="password" autocomplete="new-password" v-model="form.password"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="confirmpassword"
            label="Confirm password">
            <b-input-group>
                <b-form-input type="password" v-model="form.confirm_password"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-group
            id="email"
            label="Email">
            <b-input-group>
                <b-form-input v-model="form.email"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="admin"
            label="Admin">
            <b-input-group>
                <b-form-input v-model="form.admin"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="telephone"
            label="2FA Phone #">
            <b-input-group>
                <b-form-input v-model="form.telephone"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-group
                id="security_question_answers"
                label="Security Question Answers">
                <b-form-textarea v-model="form.security_question_answers"
                placeholder="Enter security questions with answers"></b-form-textarea>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row v-if="hasPermission('assign_resources')">
        <b-col>
            <b-form-group
                id="selected_practice"
                label="Assign to a Practice">
                <practice-multiselect
                    v-model="selected_practice"
                    :multiple="false" />
            </b-form-group>
        </b-col>
    </b-row>
    <b-row v-if="hasPermission('assign_resources')">
        <b-col>
            <b-form-group
                id="selected_workers"
                label="Assign to Worker(s)">
                <worker-multiselect
                    v-model="selected_workers"
                    :multiple="true"
                    />
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col style="text-align:center">
            <b-button @click="onSubmit" class="avo-primary-btn">
                Create Login
            </b-button>
        </b-col>
    </b-row>
</b-form>
</b-modal>
</div>
</template>

<script>
import { make_url, authenticated_request, permissionsMixin, alertsMixin } from '../../common.js'
import PracticeMultiselect from '../multiselects/PracticeMultiselect.vue'
import WorkerMultiselect from '../multiselects/WorkerMultiselect.vue'

export default {
  name: 'PayerResourceAdd',
  mixins: [permissionsMixin, alertsMixin],
  components:{
      PracticeMultiselect,
      WorkerMultiselect,
  },
  data: function(){
      return {
            form: {
                master_password: null,
                name: null,
                flag: null,
                type: null,
                url: null,
                email: null,
                username: null,
                password: null,
                confirm_password: null,
                security_question_answers: null,
                admin: null,
                telephone: null,
            },
            selected_workers: [], 
            selected_practice: null,
            resource: {},
            passwords: ["password", "confirm_password", "master_password"],
            required_form_inputs: ["name",],
            optional_form_inputs: ["flag", "url", "username", "type", "email", "security_question_answers", "admin", "telephone", "selected_practice"],
      }
  },
  methods: {
    clearData(){
        this.form = {
            master_password: null,
            name: null,
            flag: null,
            type: null,
            url: null,
            email: null,
            username: null,
            password: null,
            confirm_password: null,
            security_question_answers: null,
            admin: null,
            telephone: null,
        };
        this.resource = {}
        this.selected_workers = [];
        this.selected_practice = null;
        this.hideError();
    },
    validateInputs(){
        this.hideError();
        this.resource = {};
        
        //validate inputs
        for (var i = 0; i < this.required_form_inputs.length; i++){
            if ((this.form[this.required_form_inputs[i]] == null) || (this.form[this.required_form_inputs[i]] == "")){
                this.showError("Missing field: " + this.required_form_inputs[i]);
                return false;
            }
            this.resource[this.required_form_inputs[i]] = this.form[this.required_form_inputs[i]];
        }
        for (i = 0; i < this.optional_form_inputs.length; i++){
            if ((this.form[this.optional_form_inputs[i]] != null) && (this.form[this.optional_form_inputs[i]] != "")){
                this.resource[this.optional_form_inputs[i]] = this.form[this.optional_form_inputs[i]];
            }
        }
        if (this.form.password != this.form.confirm_password){
            this.showError("Passwords do not match");
            return false;
        }
        
        return true
    },
    async addResource(){
        const url = make_url("/resources");
        const self = this;
        const add_resource = authenticated_request({
            method: "post",
            url: url,
            data: this.resource
        }).catch(function(error){
            self.showError(error);
        }).then(function(response) {
            const resource_id = response.data;
            return resource_id
        });

        const resource_id = await add_resource;

        const promise = self.assignWorkersToLogin(resource_id)
        .then(function(){
            return self.addPayerAndPracticeToResource(resource_id)
        }).then(function(){
            return self.setResourcePassword(resource_id)
        }).then(function(response){
            if (response){
                self.$bvModal.hide('new-payer-resource');
                self.$emit('submitted');
            }
        })
        return promise;
    },
    setResourcePassword(resource_id){
        const url = make_url("/resources/" + resource_id + "/passwords");
        const self = this;
        const promise = authenticated_request({
            method: "put",
            url: url,
            data: {
                'password': this.form.password,
                'master_password': this.form.master_password
            }
        }).catch(function(){
            self.deleteResource(resource_id);
            self.showError("Invalid password");
        });
        return promise;
    },
    addPayerAndPracticeToResource(resource_id){
        // Also assigns to practice if one was selected
        const url = make_url("/resources/" + resource_id + "/practice/payer");
        const self = this;
        const promise = authenticated_request({
            method: "post",
            url: url,
            data: {
                'payer_id': this.payer_id,
                'practice_id': this.selected_practice ? this.selected_practice.practice_id : null
            }
        }).catch(function(error){
            self.deleteResource(resource_id);
            self.showError(error);
        });
        return promise;
    },
    assignWorkersToLogin(resource_id){
        var promise;
        var workers_list = [];
        for (var i = 0; i < this.selected_workers.length; i++){
            workers_list.push(this.selected_workers[i].worker_id);
        }
        if (workers_list.length > 0) {
            const url = make_url("/workers/resources/" + resource_id);
            const self = this;
            const promise = authenticated_request({
                method: "post",
                url: url,
                data: {
                    'workers': workers_list
                }
            }).catch(function(error){
                self.deleteResource(resource_id);
                self.showError(error);
            });
        } else {
            promise = Promise.resolve()
        }
        return promise
    },
    deleteResource(resource_id){
        //delete resource if any steps after its creation fail
        const url = make_url("/resources/" + resource_id);
        const promise = authenticated_request({
            method: "delete",
            url: url,
        });
        return promise;
    },
    onSubmit(){
        if (this.validateInputs()){
            this.addResource();
        }
    },
    resetModal(){
        this.hideError();
        this.clearData();
    },
    showError(alertMessage){
        this.setShowAlert(true, alertMessage);
    },
    hideError(){
        this.setShowAlert(false);
    },
  },
    computed: {
        payer_id: function(){
            return this.$store.state.payers.selected_payer.payer_id;
        },
        flags: function(){
            return this.$store.state.payers.resource_flags;
        },
        types: function(){
            return this.$store.state.payers.resource_types;
        },
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
</style>