<template>
  <div id="edit-payer">
    <b-modal
      id="payer-edit"
      @hide="resetForm"
      title="Edit Payer"
      hide-footer
      size="lg"
    >
      <b-form>
        <b-alert v-model="showAlert" variant="danger" dismissible>
          <b>{{ alertMessage }}</b>
        </b-alert>
        <div>
          <b-card class="mb-4">
            <b-form-group
              label="Payer Details"
              label-cols-lg="3"
              label-size="lg"
            >
              <b-form-group id="name" label="Company Name">
                <b-input-group>
                  <b-form-input v-model="payer.name"></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group id="name" label="Payer EDI">
                <b-input-group>
                  <b-form-input v-model="payer.edi"></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group id="name" label="Claims Portal">
                <b-input-group>
                  <b-form-input v-model="payer.claims_portal"></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group id="timely_filing" label="Timely Filing">
                <b-input-group>
                  <b-form-input v-model="payer.timely_filing"></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group id="payer_type" label="Payer Type">
                <b-form-select v-model="payer.payer_type" :options="payerTypes">
                  <template #first>
                    <b-form-select-option :value="null" disabled
                      >Select type</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>
            </b-form-group>
          </b-card>

          <b-card class="mb-4">
            <b-form-group
              label="Preauthorizations Submission Details"
              label-size="lg"
              label-cols-lg="3"
            >
              <b-form-group id="name" label="Preauths Portal Website">
                <b-input-group>
                  <b-form-input v-model="payer.preauths_portal"></b-form-input>
                </b-input-group>
              </b-form-group>

              <b-form-row>
                <b-col>
                  <b-form-group id="name" label="Preauths Phone">
                    <b-input-group>
                      <b-form-input
                        v-model="payer.preauths_phone"
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group id="name" label="Preauths Fax">
                    <b-input-group>
                      <b-form-input v-model="payer.preauths_fax"></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-form-group>
          </b-card>
          <b-card>
            <b-form-group
              label="Address and Contact Info"
              label-size="lg"
              label-cols-lg="3"
            >
              <b-form-group id="address_line1" label="Street address">
                <b-input-group>
                  <b-form-input
                    v-model="new_address.address_line1"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>

              <b-form-row>
                <b-col>
                  <b-form-group id="city" label="City">
                    <b-input-group>
                      <b-form-input v-model="new_address.city"></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group id="state" label="State">
                    <b-input-group>
                      <b-form-input v-model="new_address.state"></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group id="zip" label="Zip">
                    <b-input-group>
                      <b-form-input v-model="new_address.zip"></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-form-group id="telephone" label="Claim Phone">
                    <b-input-group>
                      <b-form-input
                        v-model="new_address.telephone"
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group id="fax" label="Fax">
                    <b-input-group>
                      <b-form-input v-model="new_address.fax"></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-group id="email" label="Billing Email">
                <b-input-group>
                  <b-form-input v-model="new_address.email"></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group id="website" label="Website">
                <b-input-group>
                  <b-form-input v-model="new_address.website"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-form-group>
          </b-card>
          <div class="mt-4" style="text-align: right">
            <b-button
              @click="submitPayer"
              variant="outline-primary"
              class="avo-primary-btn"
              :disabled="submitting"
            >
              <b-overlay :show="submitting" variant="white"
                >Submit Payer Changes</b-overlay
              >
            </b-button>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { make_url, authenticated_request, alertsMixin } from "../../common.js";

export default {
  name: "PayerEdit",
  data: function () {
    return {
      payer: {
        name: null,
        edi: null,
        payer_type: -1,
        timely_filing: null,
        claims_portal: null,
        preauths_portal: null,
        preauths_phone: null,
        preauths_fax: null,
      },
      address: {
        address_line1: null,
        city: null,
        state: null,
        zip: null,
        fax: null,
        telephone: null,
        website: null,
        email: null,
      },
      new_address: {
        address_line1: null,
        city: null,
        state: null,
        zip: null,
        fax: null,
        telephone: null,
        website: null,
        email: null,
      },
      payer_types: [],

      submitting: false,
    };
  },
  mixins: [alertsMixin],
  methods: {
    setAddress: function (address_id) {
      const url = make_url("/addresses/" + address_id).toString();
      const self = this;
      authenticated_request({
        method: "get",
        url: url,
      })
        .then(function (response) {
          if (response) {
            self.hideError();
            var address = response.data;
            for (var key in self.address) {
              self.address[key] = address[key];
              self.new_address[key] = address[key];
            }
          }
        })
        .catch(function (error) {
          self.showError(error);
        })
        .finally(() => {
          this.$emit("loaded");
        });
    },
    submitPayer() {
      this.submitting = true;

      var data = {
        payer: null,
        address: null,
      };
      var data_payer = {};
      for (var key in this.payer) {
        if (this.payer[key] != this.selectedPayer[key]) {
          data_payer[key] = this.payer[key];
        }
      }
      if (Object.keys(data_payer).length > 0) {
        data["payer"] = data_payer;
      }
      var data_address = {};
      for (key in this.new_address) {
        if (this.new_address[key] != this.address[key]) {
          data_address[key] = this.new_address[key];
        }
      }
      if (Object.keys(data_address).length > 0) {
        data["address"] = data_address;
      }

      if (data["payer"] == null && data["address"] == null) {
        this.$bvModal.hide("payer-edit");
        this.submitting = false;
        return;
      }

      var self = this;
      authenticated_request({
        method: "put",
        url: make_url("/forms/payer_with_address/" + this.payer.payer_id),
        data: data,
      })
        .then(function (response) {
          if (response) {
            self.refreshSelectedPayerData();
          }
        })
        .catch(function (errors) {
          self.showError(errors);
          self.submitting = false;
        });
    },
    refreshSelectedPayerData() {
      var self = this;
      var url = make_url("/reports/payers");
      authenticated_request({
        method: "get",
        url: url,
        params: {
          payer_id: this.selectedPayer.payer_id,
        },
      })
        .then(function (response) {
          var payer = response.data.payers[0];
          self.$store.commit("payers/selectPayer", payer);
          self.address = Object.assign({}, self.new_address);
          self.$bvModal.hide("payer-edit");
        })
        .catch(function (errors) {
          self.showError(errors);
        })
        .finally(() => {
          self.submitting = false;
        });
    },
    resetForm() {
      this.payer = Object.assign({}, this.selectedPayer);
      this.new_address = Object.assign({}, this.address);
    },
    showError(alertMessage) {
      this.setShowAlert(true, alertMessage);
    },
    hideError() {
      this.setShowAlert(false);
    },
  },
  computed: {
    selectedPayer() {
      return this.$store.state.payers.selected_payer;
    },
    payerTypes() {
      return this.$store.state.payers.payer_types;
    },
  },
  watch: {
    selectedPayer: function () {
      this.payer = Object.assign({}, this.selectedPayer);
    },
  },
  async mounted() {
    this.setAddress(this.selectedPayer.address);
    this.resetForm();
  },
};
</script>

<style scoped>
</style>