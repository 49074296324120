<template>
<b-container v-if="payer" class="p-0">
    <b-alert
        v-if="!modalAlert"
        v-model="showAlert"
        variant="danger"
        style="margin-left:20px"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <b-alert
        v-model="showSuccess"
        variant="success"
        dismissible>
        <b>Copied to clipboard</b>
    </b-alert>
    <div class="d-flex justify-content-between" style="margin-left:20px">
        <div>
            <b-input-group class="search-group">
                <b-input-group-prepend>
                    <span class="input-group-text"><b-icon icon="search"></b-icon></span>
                </b-input-group-prepend>
                <b-form-input
                    @keyup="debounceResources"
                    @search="debounceResources"
                    class="resource-search-input pl-1"
                    type="search" v-model="search_params.name"
                    placeholder="Search resources"/>
                <b-input-group-append>
                    <span class="divider"></span>
                    <b-dropdown class="dropdown filter-dropdown" :boundary="'window'">
                        <template #button-content>
                        <b-icon-funnel class="mr-2" />{{filterInputLabel}}
                        </template>
                        <b-dropdown-form class="d-flex flex-column align-items-start">
                        <span style="font-size:20px;font-weight:300">Filters</span>
                        
                        <div class="selecter-label">Type</div>
                        <multiselect 
                            v-model="filters.types"
                            :hide-selected="true"
                            :options="types"
                            track-by="type" 
                            label="name"
                            :multiple="true"
                            :show-labels="false"
                            placeholder="-- Select an Option --">
                        </multiselect>
                        
                        <div class="selecter-label">Status</div>
                        <multiselect 
                            v-model="filters.flags"
                            :hide-selected="true"
                            :options="flags"
                            track-by="flag" 
                            label="name"
                            :multiple="true"
                            :show-labels="false"
                            placeholder="-- Select an Option --">
                        </multiselect>
                        
                        <div class="d-flex justify-content-start mt-4">
                            <b-button @click="doSearch" variant="outline-primary" class="avo-primary-btn mr-5">Apply</b-button>
                            <b-button @click="clearFilters" variant="outline-primary" class="avo-basic-btn">Clear Filters</b-button>
                        </div>
                        </b-dropdown-form>
                    </b-dropdown>
                </b-input-group-append>
            </b-input-group>
        </div>
        <div style="margin-right:20px">
            <b-button v-if="hasPermission('edit')" v-b-modal.new-payer-resource class="avo-primary-btn">Add Login</b-button>
            <payer-resource-add @submitted="getResources()" />
        </div>
    </div>
    <div v-if="resources.length > 0" style="margin-left:20px; margin-right:20px">
        <b-table-simple borderless class="resource-table mt-4">
            <b-thead style="font-size:14px !important;">
                <b-tr>
                    <b-th>
                        Active
                    </b-th>
                    <b-th>
                        Practice
                    </b-th>
                    <b-th>
                        Name
                    </b-th>
                    <b-th>
                        Username
                    </b-th>
                    <b-th>
                        Password
                    </b-th>
                    <b-th>
                        Portal
                    </b-th>
                    <b-th>
                        Updated
                    </b-th>
                    <b-th>
                    </b-th>
                </b-tr>
            </b-thead>
            <b-tbody style="font-size:14px !important;">
                <b-tr v-for="(resource,index) in resources" :key="index">
                    <b-td>
                        <b-icon v-bind="activeIcon(active[index])" />
                    </b-td>
                    <b-td>
                        {{resource.practice ? resource.practice.name : 'N/A'}}
                    </b-td>
                    <b-td>
                        {{resource.name}}
                    </b-td>
                    <b-td>
                        {{resource.username || '--'}}
                    </b-td>
                    <b-td>
                        <b-icon class="mr-2" icon="clipboard"></b-icon>
                        <u @click="askToCopyPassword(index)" style="cursor:pointer">Copy password</u>
                        <b-icon v-if="masterPasswordNeeded(index)" class="ml-2" icon="lock"></b-icon>
                        <b-modal 
                            title="Enter master password"
                            :id="'password-modal-'+index" 
                            hide-footer 
                            @hide="clearPasswordModalData()">
                            <b-alert
                                v-if="modalAlert"
                                v-model="showAlert"
                                variant="danger"
                                style="margin-left:20px"
                                dismissible>
                                <b>{{alertMessage}}</b>
                            </b-alert>
                            <div class="avo-header-text" style="text-align:left">
                                Practice master resource password
                            </div>
                            <div class="mt-4 mr-4 ml-4">
                                <!-- Note: adding type="password" to this will make Chrome think it should autofill a svaed password -->
                                <b-form-input style="text-security:disc; -webkit-text-security:disc;" v-model="user_input"></b-form-input>
                            </div>
                            <div class="mt-4" style="text-align:center">
                                <b-button @click="getPassword(index)" class="avo-primary-btn">Submit password</b-button>
                            </div>
                        </b-modal>
                    </b-td>
                    <b-td class="">
                        <b-button class="avo-basic-btn" v-if="resource.url" @click="loginPortal(resource.url)">Login</b-button>
                        <div v-else>--</div>
                    </b-td>
                    <b-td>
                        {{formatDate(resource.updated)}}
                    </b-td>
                    <b-td>
                        <b-dropdown class="resource-dropdown" no-caret style="min-width:none" :right="true">
                            <template #button-content>
                            <b-icon icon="three-dots"></b-icon>
                            </template>
                            <b-dropdown-item @click="viewRecoveryModal(index)">View Recovery Info</b-dropdown-item>
                            <b-dropdown-item @click="editResource(index)">Edit</b-dropdown-item>
                            <b-dropdown-item @click="archiveResource(index)">Archive</b-dropdown-item>
                        </b-dropdown>
                        <b-modal :id="'recovery-modal-'+index" hide-footer hide-header>
                            <div class="avo-header-text" style="text-align:center">
                                Recovery Information for {{resource.name}}
                            </div>
                            <hr>
                            <div class="mt-4" style="text-align:center">
                                Resource Admin
                            </div>
                            <div class="mt-2 bold" style="text-align:center">
                                {{resource.admin || "N/A"}}
                            </div>
                            <div class="mt-4" style="text-align:center">
                                Email Listed
                            </div>
                            <div class="mt-2 bold" style="text-align:center">
                                {{resource.email || "N/A"}}
                            </div>
                            <div class="mt-4" style="text-align:center">
                                2FA Phone #:
                            </div>
                            <div class="mt-2 bold" style="text-align:center">
                                {{resource.telephone || "N/A"}}
                            </div>
                            <div class="mt-4" style="text-align:center">
                                Security Question Answers:
                            </div>
                            <div class="mt-2 bold" style="text-align:center">
                                {{resource.security_question_answers || "N/A"}}
                            </div>
                            <div class="mt-4" style="text-align:center">
                                <b-button @click="closeRecoveryModal(index)" class="avo-basic-btn">Close</b-button>
                            </div>
                        </b-modal>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
        <div>
            <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="page" align="center" />
        </div>
        <payer-resource-edit @submitted="getResources()" />
    </div>
    <div v-else-if="loaded" style="text-align:center">
        No login information found for this payer
    </div>
</b-container>
</template>

<script>
import { make_url, authenticated_request, debounce, alertsMixin, permissionsMixin, formatDateShort as formatDate } from '../../common.js'
import Multiselect from 'vue-multiselect'
import PayerResourceAdd from './PayerResourceAdd.vue'
import PayerResourceEdit from './PayerResourceEdit.vue'

export default {
    name: 'PayerResourceLogins',
    mixins: [alertsMixin, permissionsMixin,],
    components: {
        PayerResourceAdd,
        PayerResourceEdit,
        Multiselect
    },
    data(){
        return {
            resources: [],
            loaded: false,
            active: [],
            user_input: "",
            archive_flag: 0,
            filters: {
                flags: [],
                types: []
            },
            search_params: {
                name: "",
                flags: "",
                types: ""
            },
            perPage: 20,
            totalRows: 1,
            page: 1,
            
            showSuccess: false,
            modalAlert: false,
        }
    },
    methods: {
        activeIcon(value){
            let icon, cls;
            if(value == 1){
                icon = "check";
                cls = "text-primary h2";
            }
            else if(value == -1){
                icon = "question";
                cls = "h2";
            }
            else {
                icon = "x";
                cls = "text-danger h2";
            }
            return {
                'icon': icon,
                'class': cls
            }
        },
        getResources(clear_page=true){
            var params = this.search_params;
            params.payer_id = this.payer_id;
            params.per_page = this.perPage;
            if(clear_page) {
                params.page = 1;
            }
            else {
                params.page = this.page;
            }
            //if(this.sortBy){
            //    params.sort = this.sortBy + ":" + (this.sortDesc?"desc":"asc")
            //}
            const url = make_url("/resources");
            const self = this;
            const promise = authenticated_request({
                method: "get",
                url: url,
                params: params
            }).then(function(response){
                if (response) {
                    var resources = response.data.resources;
                    self.resources = resources;
                    self.totalRows = response.data.pagination.total;
                    self.perPage = response.data.pagination.per_page;
                    self.loaded = true;
                    return resources || [];
                }
            }).catch(function(error){
                self.showError(error);
                self.loaded = true;
            });
            
            return promise;
        },
        getFilters(){
            const url = make_url("/resources/filters");
            const self = this;
            const promise = authenticated_request({
                method: "get",
                url: url,
            }).then(function(response){
                self.hideError();
                var flags = response.data.flags;
                delete flags['-1'];
                self.$store.commit("payers/setResourceFlags", flags);
                var types = response.data.types;
                delete types['-1'];
                self.$store.commit("payers/setResourceTypes", types);
                var workers = response.data.workers;
                self.$store.commit("payers/setWorkers", workers);
                var practices = response.data.practices;
                self.$store.commit("payers/setPractices", practices);
            }).catch(function(error){
                self.showError(error);
            });
            return promise;
        },
        doSearch(){
            this.page = 1;
            this.getResources();
        },
        clearFilters(){
            this.filters.flags = [];
            this.filters.types = [];
            this.search_params.flags = "";
            this.search_params.types = "";
            this.doSearch();
        },
        loginPortal(url){
            var portal = url;
            if (url.indexOf("http://") != 0 && url.indexOf("https://") != 0){
                portal = "http://" + portal;
            }
            window.open(portal);
        },
        formatDate,
        masterPasswordNeeded(index){
            if (this.resources[index].assigned_workers.split(',')?.includes(this.worker_id) ||
                this.resources[index].is_public) {
                    console.log("Master password not needed")
                    return false
                } else {
                    console.log("Master password is needed")
                    return true
                }
        },
        askToCopyPassword(index){
            if (this.masterPasswordNeeded(index)) {
                this.passwordModal(index)
            } else {
                this.getPassword(index)
            }
        },
        passwordModal(index){
            this.$bvModal.show('password-modal-'+index);
        },
        viewRecoveryModal(index){
            this.$bvModal.show('recovery-modal-'+index);
        },
        closeRecoveryModal(index){
            this.$bvModal.hide('recovery-modal-'+index);
        },
        getPassword(index){
            const url = make_url("/resources/" + this.resources[index].resource_id + "/passwords");
            const self = this;
            const promise = authenticated_request({
                method: "post",
                url: url,
                data: {
                    'master_password': this.user_input
                }
            }).then(function(response){
                if (response) {
                    self.copyTextToClipboard(response.data.password);
                    self.$bvModal.hide('password-modal-'+index);
                }
            }).catch(function(error){
                //show error in modal
                if (error.response.status == 400){
                    self.showError("Invalid password", true)
                }
                else{
                    self.showError(error, true);
                }
            });
            
            return promise;
        },
        async copyTextToClipboard(password){
            await navigator.clipboard.writeText(password);
            this.showSuccess = true;
        },
        archiveResource(index){
            const url = make_url("/resources/" + this.resources[index].resource_id).toString();
            const self = this;
            const promise = authenticated_request({
                method: "put",
                url: url,
                data: {
                    'flag': this.archive_flag
                }
            }).then(function(response){
                if (response) {
                    self.resources[index].flag = self.archive_flag;
                    self.$set(self.active, index, false);
                }
            }).catch(function(error){
                self.showError(error);
            });
            
            return promise;
        },
        editResource(index){
            this.$store.commit("payers/selectResource", this.resources[index]);
            this.$bvModal.show('edit-payer-resource');
        },
        clearPasswordModalData(){
            this.user_input = "";
            if (this.modalAlert){
                this.hideError();
            }
        },
        
        //alert methods
        showError(alertMessage, modal=false){
            this.modalAlert = modal;
            this.setShowAlert(true, alertMessage);
        },
        hideError(){
            this.modalAlert = false;
            this.setShowAlert(false);
        },
    },
    computed: {
        payer: function(){
            return this.$store.state.payers.selected_payer;
        },
        worker_id: function(){
            return localStorage.getItem('worker_id');
        },
        payer_id: function(){
            return this.$store.state.payers.selected_payer.payer_id;
        },
        filterCount: function() {
            var count = 0;
            if (this.search_params['flags'].length > 0){
                count = count + 1;
            }
            if (this.search_params['types'].length > 0){
                count = count + 1;
            }
            return count;
        },
        filterInputLabel: function(){
            const count = this.filterCount;
            if(count == 0) {
                return "Filters";
            } else {
                return count + " Filter(s)";
            }
        },
        //convert type and flag objects to arrays of objects
        types: function(){
            var types = []
            for (const key in this.$store.state.payers.resource_types){
                types.push(
                    {
                        name: this.$store.state.payers.resource_types[key], 
                        type: key
                    }
                )
            }
            return types;
        },
        flags: function(){
            var flags = []
            for (const key in this.$store.state.payers.resource_flags){
                flags.push(
                    {
                        name: this.$store.state.payers.resource_flags[key], 
                        flag: key
                    }
                )
            }
            return flags;
        }
    },
    watch: {
        resources: function(){
            var active = []
            for (var i = 0; i < this.resources.length; i++){
                active.push((this.resources[i].flag));
            }
            this.active = active;
        },
        'filters.flags': function(newValues) {
            this.search_params.flags = newValues.map(obj => obj.flag).join();
        },
        'filters.types': function(newValues) {
            this.search_params.types = newValues.map(obj => obj.type).join();
        },
        page: function() {
            this.getResources(false);
        }
    },
    created() {
        this.getResources();
        this.debounceResources = debounce(this.getResources, 900);
    },
    mounted() {
        this.getFilters();
    }
}
</script>

<style scoped>
/* increasing b-containers max-width for larger screens */
@media only screen and (min-width : 1200px) {
   .container { max-width: 1400px; } 
}

/* Styling resource table */
.resource-table {
    border: 1px solid #C9D2DF;
    border-collapse: separate;
    border-spacing: 0px;
    border-radius: 5px;
}
::v-deep .resource-table th{
    padding: 20px;
    vertical-align: middle;
}
::v-deep .resource-table td{
    padding: 20px;
    vertical-align: middle;
}
::v-deep .resource-table tr:first-child th{
    border-bottom: 1px solid #C9D2DF;
}
::v-deep .resource-table tr:nth-child(odd) td{
    background:#F7F8FA;
}
::v-deep .resource-table tr:last-child td:first-child{
    border-radius:0px 0px 0px 5px;
}
::v-deep .resource-table tr:last-child td:last-child{
    border-radius:0px 0px 5px 0px;
}

::v-deep .resource-dropdown .btn-secondary {
    min-width: auto !important;
    color: var(--dark) !important;
    background: none !important;
    text-align: center !important;
}

/* Styling the search bar group */
::v-deep .search-group {
    position: relative;
    margin-right: 20px;
    border: 1px solid #C9D2DF;
    border-radius: 5px;
    max-width:650px;
}
::v-deep .resource-search-input, .input-group-text {
    height:50px;
    border: none;
    border-radius: 5px;
    background: white;
    color: #9CA3AD; 
}
::v-deep .resource-search-input::placeholder {
    color: #9CA3AD;
    font-size:12px;
    opacity: 1;
}
::v-deep .b-dropdown .btn-secondary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-radius: 5px;
    color: #9CA3AD;
    background: white;
    font-size:12px;
    height:50px;
    min-width:150px;
    text-align:left;
}
::v-deep .b-dropdown .btn-secondary:active,
::v-deep .btn-secondary:not(:disabled):not(.disabled):active, 
::v-deep .btn-secondary:not(:disabled):not(.disabled).active, 
::v-deep .show > .btn-secondary.dropdown-toggle {
    color:#519EFF !important;
    background-color: white;
}
::v-deep .b-dropdown .btn-secondary:focus {
    box-shadow:none !important;
}
.divider {
    display: inline-block;
    width: 0;
    height: 20px;
    margin: auto;
    border-right: 1.8px solid #D2D8E2;
}

/* dropdown menu-related styling */
::v-deep .filter-dropdown .b-dropdown-form {
    padding: 20px 30px 30px 30px;
}
::v-deep .filter-dropdown .dropdown-menu {
    width:100.3%;
    right: -1px !important;
    left: auto !important;
    top:45px !important;
    transform: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0px 16px 48px #1617182B !important;
}
::v-deep .flag-dropdown .dropdown-menu {
    width: 200px;
    padding: 10px;
    left: -1px !important;
    top:48px !important;
    transform: none !important;
    font-size: 14px;
    font-weight: 500;
    color: #64728C;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0px 16px 48px #1617182B !important;
}
::v-deep .flag-dropdown .b-dropdown-form {
    padding: 0.2rem 0.8rem;
}
.selecter-label {
    color: #64728C;
    font-size: 14px;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom:5px;
}
</style>