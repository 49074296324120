<template>
  <multiselect
    openDirection="bottom"
    :placeholder="placeholder"
    :custom-label="workerLabel"
    v-model="selectedWorker"
    track-by="worker_id"
    :options="selectableWorkers"
    :loading="workersLoading"
    :internal-search="true"
    :close-on-select="!multiple"
    :multiple="multiple"
    :allow-empty="allowEmpty"
    :show-labels="false"
    @open="onOpen"
    @select="onSelect"
    @remove="onRemove"
    :showNoOptions="true"
  >
    <template slot="noResult"> No workers matched your search </template>
    <template slot="noOptions"> Start typing a worker name... </template>
  </multiselect>
</template>

<script>
import { make_url, authenticated_request, debounce } from "../../common.js";
import Multiselect from "vue-multiselect";


export default {
  name: "WorkerMultiselect",
  components: {
    Multiselect,
  },
  props: {
    value: [Object, Array],
    multiple: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    allowUnassigned: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Search by worker name",
    },
  },
  data: function () {
    return {
      selectedWorker: null,
      workersLoading: false,
      workersLoaded: false,

      unassigned: { worker_id: null, full_name: "Unassigned", initials: "" },
    };
  },
  methods: {
    onOpen: function () {
      if (!this.workersLoading && !this.workersLoaded) {
        this.fetchWorkers();
      }
    },
    onSelect: function (item) {
      this.$emit("select", item);
    },
    onRemove: function (item) {
      this.$emit("remove", item);
    },
    fetchWorkers() {
      this.workersLoading = true;
      const url = make_url("/reports/workers/autocomplete");
      var vm = this;
      authenticated_request({
        method: "get",
        url: url,
      }).then(function (response) {
          const workers = response.data.workers || [];
          vm.$store.commit("saveWorkers", workers);
          vm.workersLoaded = true;
        }).catch(function (errors) {
          console.log(errors);
        }).finally(function () {
          vm.workersLoading = false;
        });
    },
    workerLabel(worker) {
      return worker.name;
    },
  },
  computed: {
    storeWorkers: function () {
      return this.$store.state.claims.workers;
    },
    selectableWorkers: function () {
      if (this.allowUnassigned) {
        return this.storeWorkers.concat([this.unassigned]);
      } else {
        return this.storeWorkers;
      }
    },
  },
  watch: {
    selectedWorker() {
      this.$emit("input", this.selectedWorker);
    },
    value: {
      deep: true,
      immediate: true,
      handler: function (newValue) {
        // push worker into workers array if it doesn't already exist
        // and it isn't the "unassigned" worker
        function push_unique(array, item) {
          if (item.worker_id && array.filter((option) => option.worker_id == item.worker_id).length == 0) {
            array.push(item);
          }
        }

        if (newValue) {
          this.selectedWorker = newValue;
          if (this.multiple) {
            newValue.map((option) => push_unique(this.selectableWorkers, option));
          } else {
              push_unique(this.selectableWorkers, newValue);
          }
        }
      },
    },
  },
  created() {
    this.debounceWorkers = debounce(this.onOpen, 300);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::v-deep .multiselect__spinner {
  background: #dde4f5 !important;
}
::v-deep .invalid .multiselect__tags {
  border: 1px solid #dc3545;
  border-radius: 5px;
}
</style>