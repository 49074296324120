<template>
<div v-if="payer">
    <div>
        <back-button @click="clearSelectedPayer()">Back to Payers</back-button>
    </div>
    <div>
        <b-alert
            v-model="showAlert"
            variant="danger"
            style="margin-left:20px"
            dismissible>
            <b>{{alertMessage}}</b>
        </b-alert>
    </div>
    <b-row class="mt-4 ml-2 mr-4">
        <b-col cols="6" class="d-flex flex-column">
            <div class="avo-text-light">
                Payer
            </div>
            <div class="avo-header-text" style="font-size:36px">
                {{payer.name}}
            </div>
        </b-col>
        <b-col cols="4" class="d-flex justify-content-center">
            <div class="mt-2 mr-2" style="padding-left:10px; border-left:4px solid var(--blue); margin-bottom:13px">
            </div>
            <div class="d-flex flex-column mr-4">
                <div>
                    <div class="avo-header-text bold" style="font-size:28px">
                        {{payerSummaryLoading ? '-' : num_pending}}
                    </div>
                    <div>
                        Pending Claims
                    </div>
                </div>
            </div>
            <div class="mt-2 ml-4 mr-2" style="padding-left:10px; border-left:4px solid #FD4739; margin-bottom:13px">
            </div>
            <div class="d-flex flex-column">
                <div class="avo-header-text bold" style="font-size:28px">
                    {{payerSummaryLoading ? '-' : num_underpaid}}
                </div>
                <div>
                    Under Payments
                </div>
            </div>
            <div class="mt-2 ml-4 mr-2" style="padding-left:10px; border-left:4px solid #41B0A5; margin-bottom:13px">
            </div>
            <div class="d-flex flex-column">
                <div class="avo-header-text bold" style="font-size:28px">
                    {{payerSummaryLoading ? '-' : num_issues}}
                </div>
                <div>
                    Issues
                </div>
            </div>
        </b-col>
        <b-col class="d-flex justify-content-end">
            <div>
                <b-button class="avo-primary-btn" v-b-modal.payer-edit :disabled="editPayerLoading"><b-overlay :show="editPayerLoading" variant="white">Edit Payer</b-overlay></b-button>
                <payer-edit @loaded="editPayerLoading = false"/>
            </div>
        </b-col>
    </b-row>
    <div class="mt-4">
        <b-nav tabs class="nav-section">
            <b-nav-item 
                v-if="hasPermission('summary_tab')" 
                :active="activeTab == 'PayerSummary'" @click="setActiveTab('PayerSummary')">Summary</b-nav-item>
            <b-nav-item 
                :active="activeTab == 'PayerClaims'" @click="setActiveTab('PayerClaims')">Claims</b-nav-item>
            <b-nav-item 
                v-if="hasPermission('resources_tab')" 
                :active="activeTab == 'PayerResourceLogins'" @click="setActiveTab('PayerResourceLogins')">Logins</b-nav-item>
        </b-nav>
        <keep-alive>
            <component class="mt-4" :is="activeTab"></component>
        </keep-alive>
    </div>
</div>
</template>

<script>
import { make_url, authenticated_request, permissionsMixin, alertsMixin } from '../../common.js'
import BackButton from '../BackButton.vue'
import PayerSummary from './PayerSummary.vue'
import PayerClaims from './PayerClaims.vue'
import PayerResourceLogins from './PayerResourceLogins.vue'
import PayerEdit from './PayerEdit.vue'

export default {
    name: 'PayerView',
    mixins: [permissionsMixin, alertsMixin],
    components: {
        BackButton,
        PayerSummary,
        PayerClaims,
        PayerResourceLogins,
        PayerEdit
    },
    data(){
        return {
            activeTab: this.hasPermission('summary_tab') ? "PayerSummary" : "PayerClaims",
            num_pending: '-',
            num_underpaid: '-',
            num_issues: '-',
            payerSummaryLoading: true,
            editPayerLoading: true,
        }
    },
    methods: {
        clearSelectedPayer(){
            this.$store.commit("payers/clearSelectedPayer");
        },
        // For tab navigation
        setActiveTab: function(component){
            this.activeTab = component;
        },
        getPendingClaims() {
            const url = make_url("/reports/payers/" + this.payer.payer_id + "/pending-claims");
            const vm = this;
            var params = {
                page: 1,
            }
            const promise = authenticated_request({
                method: "get",
                url: url,
                params: params
            }).then(function(response){
                if (response) {
                    const claims = response.data.claims;
                    vm.num_pending = claims.length;
                }
            }).catch(function(error){
                console.log(error.response)
            });
            return promise;
        },
        getUnderpaidClaims() {
            const url = make_url("/reports/claims");
            const vm = this;
            var params = {
                page: 1,
                payer_ids: this.payer.payer_id,
                min_balance: 0
            }
            const promise = authenticated_request({
                method: "get",
                url: url,
                params: params
            }).then(function(response){
                if (response) {
                    const claims = response.data.claims;
                    vm.num_underpaid = claims.length;
                }
            }).catch(function(error){
                console.log(error.response)
            });
            return promise;
        },
        getIssueClaims() {
            const url = make_url("/reports/claims");
            const vm = this;
            var params = {
                page: 1,
                issues_claims: true,
                payer_ids: this.payer.payer_id,
            }
            const promise = authenticated_request({
                method: "get",
                url: url,
                params: params
            }).then(function(response){
                if (response) {
                    const claims = response.data.claims;
                    vm.num_issues = claims.length;
                }
            }).catch(function(error){
                console.log(error.response)
            });
            return promise;
        },
        //error methods
        showError(alertMessage){
            this.showSuccess = false;
            this.setShowAlert(true, alertMessage);
        },
        hideError(){
            this.setShowAlert(false);
        },
    },
    computed: {
        payer: function(){
            return this.$store.state.payers.selected_payer;
        },
    },
    mounted() {
        const vm = this;
        this.payerSummaryLoading = true;
        Promise.all([this.getPendingClaims(), this.getUnderpaidClaims(),this.getIssueClaims()]).then(
           () => { vm.payerSummaryLoading = false }
        )
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav-section {
    border-bottom: 1px solid #E6EEF9;
    margin-left: -15px;
    margin-right: -15px;
    padding-right: 35px;
    padding-left: 35px;
}
</style>