<template>
<b-container class="p-0" >
    <b-row>
        <b-col cols="7" class="pr-0">
            <b-card class="avo-info-card mb-4">
                <div class="avo-header-text mb-4">Payer Details</div>
                <b-row class="mb-4">
                    <b-col>
                        <div class="label-text">Payer EDI</div>
                        <div class="data-text">{{payer.edi || '-'}}</div>
                    </b-col>
                    <b-col>
                        <div class="label-text">Avo ID</div>
                        <div class="data-text">{{payer.payer_id || '-'}}</div>
                    </b-col>
                    <b-col>
                        <div class="label-text">Address</div>
                        <div class="data-text">{{payer.full_address || '-'}}</div>
                    </b-col>
                </b-row>
                <b-row class="mb-4">
                    <b-col>
                        <div class="label-text">Phone</div>
                        <div class="data-text">{{payer.telephone || '-'}}</div>
                    </b-col>
                    <b-col>
                        <div class="label-text">Fax</div>
                        <div class="data-text">{{payer.fax || '-'}}</div>
                    </b-col>
                    <b-col>
                        <div class="label-text">Billing Email</div>
                        <div class="data-text">{{payer.email || '-'}}</div>
                    </b-col>
                </b-row>
                <b-row class="mb-4">
                    <b-col>
                        <div class="label-text">Claims Portal</div>
                        <div class="data-text">{{payer.claims_portal || '-'}}</div>
                    </b-col>
                    <b-col>
                        <div class="label-text">Timely Filing</div>
                        <div class="data-text">{{payer.timely_filing || '-'}} days</div>
                    </b-col>
                    <b-col>
                    </b-col>
                </b-row>
                <b-row class="mb-4">
                    <b-col>
                        <div class="label-text">Preauths Portal</div>
                        <div class="data-text">{{payer.preauths_portal || '-'}}</div>
                    </b-col>
                    <b-col>
                        <div class="label-text">Preauths Phone</div>
                        <div class="data-text">{{payer.preauths_phone || '-'}}</div>
                    </b-col>
                    <b-col>
                        <div class="label-text">Preauths Fax</div>
                        <div class="data-text">{{payer.preauths_fax || '-'}}</div>
                    </b-col>
                </b-row>
            </b-card>
            <payer-source-refs :payer="payer"/>
            <b-overlay :show="chartLoading">
            <b-card class="summary-card-table mb-4">
                <div class="d-flex flex-row justify-content-between px-3 pb-3">
                    <div class="summary-card-header-txt">
                        Pending Claims
                    </div>
                    <div class="d-flex align-items-center justify-content-end w-50">
                        <div class="mr-2">By age</div>
                        <multiselect
                            v-model="pendingAgeRange"
                            :hide-selected="true"
                            :options="ageRangeOptions"
                            track-by="value"
                            class="w-50"
                            label="text"
                            :show-labels="false"
                            placeholder="Choose a range">
                        </multiselect>
                    </div>
                </div>
                <b-table id="pending-claims-table" 
                    :items="getPendingClaims"
                    :fields="pendingClaimsFields"
                    class="avo-table-embedded"
                    primary-key="claim_id"
                    ref="pendingClaimsTable"
                    show-empty
                    empty-text="No pending claims"
                    striped>
                    
                    <template #cell(claim_id)="data">
                    <div><strong>{{ data.value }}</strong></div>
                    </template>
                    
                    <template #cell(patient_name)="data">
                    <div>{{ data.value }} </div>
                    </template>

                    <template #cell(provider_name)="data">
                    <div>{{ data.value }} </div>
                    </template>

                    <template #cell(last_action)="data">
                    <div>{{ formattedFullDate(data.value.created) }} </div>
                    </template>

                    <template #cell(timely_days_left)="data">
                    <div>{{ data.value }} days</div>
                    </template>
                </b-table>
            </b-card>
            </b-overlay>
            <b-overlay :show="chartLoading">
            <b-card class="summary-card-table">
                <payer-underpayments :payer="payer"/>
            </b-card>
            </b-overlay>
        </b-col>
        <b-col cols="5">
            <b-card class='summary-card mb-4' style="height:400px">
                <div class="d-flex flex-row justify-content-start">
                    <div class="summary-card-header-txt">
                        Age of Claims
                    </div>
                </div>
                <div class="chart-container">
                    <div v-if="chartLoading" class="spinner-container">
                        <b-spinner></b-spinner>
                    </div>
                    <bar-chart :chartData="barChartData" :styles="{height:'100%', position:'relative'}"></bar-chart>
                </div>
            </b-card>
            <b-overlay :show="proceduresDataLoading">
            <b-card class='summary-card mb-4'>
                <div class="d-flex flex-row justify-content-start pb-4">
                    <div class="summary-card-header-txt">
                        Top Procedures by Billing
                    </div>
                </div>
                <div v-if="topProcedures.length == 0" style="text-align:center">
                    No data to display
                </div>
                <div v-else>
                    <div class="d-flex justify-content-between align-items-center mb-3" style="color:#38455D;font-weight: 500;font-size:14px;">
                        <div>Procedure</div>
                        <div>Amount</div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mb-3"
                        v-for="(item, index) in topProcedures" :key="index">
                        <div>{{item.short}}</div>
                        <div style="color:#139F92;font-weight:500">${{item.count}}</div>
                    </div>
                </div>
            </b-card>
            </b-overlay>
        </b-col>
    </b-row>
</b-container>
</template>

<script>
import { make_url, authenticated_request, formatFullDate, permissionsMixin } from '../../common.js'
import Multiselect from 'vue-multiselect'
import PayerSourceRefs from './PayerSourceRefs.vue'
import BarChart from '../charts/BarChart.vue'
import PayerUnderpayments from './PayerUnderpayments.vue'

export default {
    name: 'PayerSummary',
    mixins: [permissionsMixin],
    components: {
        PayerSourceRefs,
        Multiselect,
        BarChart,
        PayerUnderpayments,
    },
    data(){
        return {
            ageRangeOptions: [
                {text: "All ages", value:""},
                {text: "0-30 days", value:"0,30"},
                {text: "31-60 days", value:"31,60"},
                {text: "61-90 days", value:"61,90"},
                {text: "91-120 days", value:"91,120"},
                {text: "120+ days", value:"121,9999"}
            ],
            pendingAgeRange: {text: "All ages", value:""},

            topProcedures: [],
            chartLoading: true,
            proceduresDataLoading: true,
            barChartData: {
                labels: [],
                datasets: [
                    {
                        backgroundColor: '#A8C3F3',
                        data: []
                    }
                ]
            },
        }
    },
    methods: {
        getPendingClaims() {
            const url = make_url("/reports/payers/" + this.payer.payer_id + "/pending-claims");
            // const self = this;
            var params = {
                page: 1,
                age_ranges: this.pendingAgeRange.value
            }
            const promise = authenticated_request({
                method: "get",
                url: url,
                params: params
            }).then(function(response){
                if (response) {
                    const claims = response.data.claims;
                    return claims || [];
                }
            }).catch(function(error){
                console.log(error.response)
            });
            return promise;
        },
        getClaimAgeTotals() {
            const url = make_url("/reports/claims/age-summary");
            const vm = this;
            
            var params = {
                payer_id: this.payer.payer_id
            }
            const promise = authenticated_request({
                method: "get",
                url: url,
                params: params
            }).then(function(response){
                if (response) {
                    const items = response.data;
                    var values = [];
                    var labels = [];
                    for (const item of items){
                        values.push(item.total);
                        labels.push(item.label);
                    }
                    vm.chartLoading = false;
                    vm.barChartData.datasets[0].data = values;
                    vm.barChartData.labels = labels;
                }
            }).catch(function(error){
                console.log(error.response)
            });
            return promise;
        },
        getClaimTopProcedures() {
            const url = make_url("/reports/claims/procedures");
            const vm = this;
            var params = {
                payer_id: this.payer.payer_id
            }
            const promise = authenticated_request({
                method: "get",
                url: url,
                params: params
            }).then(function(response){
                if (response) {
                    const items = response.data.procedures_by_billing;
                    vm.topProcedures = items;
                }
            }).catch(function(error){
                console.log(error.response)
            }).finally(()=>{
                vm.proceduresDataLoading = false;
            });
            return promise;
        },
        formattedFullDate(datetime) {
            return datetime ? formatFullDate(datetime) : 'None';
        },
    },
    computed: {
        payer: function(){
            return this.$store.state.payers.selected_payer;
        },
        pendingClaimsFields: function(){
          return [
            {
                label: 'Claim',
                key: 'claim_id',
                tdClass: 'max-width-col'
            },
            {
                label: 'Patient',
                key: 'patient_name',
            },
            {
                label: 'Provider',
                key: 'provider_name',
            },
            {
                label: 'Last Action',
                key: 'last_action',
                thClass: 'right-align-col',
                tdClass: 'right-align-col'
            },
            {
                label: 'Time Left',
                key: 'timely_days_left',
                thClass: 'right-align-col',
                tdClass: 'right-align-col'
            },
          ];
        },
        canEdit: function(){
            return this.hasPermission('edit');
        },
    },
    watch: {
        pendingAgeRange: function() {
            this.$refs.pendingClaimsTable.refresh();
        },
    },
    async mounted () {
        this.getClaimAgeTotals();
        this.getClaimTopProcedures();
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* increasing b-containers max-width for larger screens */
@media only screen and (min-width : 1200px) {
   .container { max-width: 1400px; } 
} 

.summary-card {
  border-radius: 5px 5px 5px 5px;
  border: 1px solid #E2E4EB;
}
.summary-card-table {
  border-radius: 5px 5px 5px 5px;
  border: 1px solid #E2E4EB;
}
.summary-card-table .card-body{
  padding: 1rem 0px 0px 0px !important;
}
.avo-info-card {
    padding: 10px;
    min-height: 175px;
}
.chart-container {
    display: flex; 
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 16px 16px 24px 0;
}
.spinner-container {
    position: absolute;
    top:50%;
    left:45%
}

</style>